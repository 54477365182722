











import Vue from 'vue'
export default Vue.extend({
    props:['group' , 'loading'],
    data(){
        const groupType:string = "1"
        return {
            groupType ,
            imgPath : process.env.VUE_APP_IMG_URL
        }
    },
    mounted(){
       this.groupType = this.$route.query.groupType  ? this.$route.query.groupType as string : "1"
    },
})
