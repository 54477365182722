































import Vue from 'vue'
import {mapGetters} from 'vuex'
import loading from '@/components/layouts/loading.vue'
import NoItems from '@/components/layouts/noItems.vue'
import CartItem from './cartItem.vue'

export default Vue.extend({
    components:{
        loading,
        "cart-item" : CartItem,
        "no-items" : NoItems
    },
    computed:{
        ...mapGetters({
            'loading' : 'cart/loading',
            'items' : 'cart/items',
            'totals' : 'cart/totals',
        })
    },
    
})
