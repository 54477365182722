





















import CartRepo from "@/modules/cart/cart.repo";
import Vue from 'vue'
import {CartItem } from "@/modules/cart/cart.model";
import { snackBarModel } from '@/utils/snackbar.model';
    
    
let repo = new CartRepo();
export default Vue.extend({
    props:['item'],
    methods:{
        deleteItem(item:CartItem){
            repo.deleteItem(item.CartItemSerial).then(() => {
                this.$store.commit("ui/stopSnack")
                let snack :snackBarModel = {
                  Active: true,
                  Title: 'success',
                  Msg: 'removed from cart successfully'
                }
                this.$store.commit("ui/setSnack" , snack)
                this.$store.commit("cart/remove" , item)
            })

        },
    }
})
