import { render, staticRenderFns } from "./subGroup.vue?vue&type=template&id=23c4b857&scoped=true&"
import script from "./subGroup.vue?vue&type=script&lang=ts&"
export * from "./subGroup.vue?vue&type=script&lang=ts&"
import style0 from "@/assets/scss/partials/sub-group.css?vue&type=style&index=0&id=23c4b857&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c4b857",
  null
  
)

export default component.exports