



























import {Item , convertToCart} from '@/modules/item/item.model'
import Vue from 'vue'
import {  CreateCartItemReq } from "@/modules/cart/cart.model";
import CartRepo from "@/modules/cart/cart.repo";
let repo =  CartRepo.getInstance();
import {openSnack} from '@/core/snack'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)
export default Vue.extend({
    props:['item' , 'loading'],
    data(){
        const groupType:string = "1"
        return {
            inCart:false,
            imgPath : process.env.VUE_APP_IMG_URL,
            groupType
        }
    },
    mounted(){
       this.groupType = this.$route.query.groupType  ? this.$route.query.groupType as string : "1"
    },
    methods:{
        // show() {
        //     this.$viewerApi({
        //     images: ['https://oddmenu.com:3000/image/126628348688563.jpg'],
        //     })
        // },
        createCartInstance(item : Item) {
            repo.create().then(() => {
                this.addItemToCart(item);
            })
        },
        addItemToCart(item:Item){
            if(repo.cartSerial == 0) {
                this.createCartInstance(item)
                return
            }            let req : CreateCartItemReq = {
              ItemSerial: item.ItemSerial,
              CartSerial: 0,
              Price: item.ItemPrice
            }
            repo.createItem(req).then((res) => {
                openSnack("success" , "added to cart successfully")
                this.inCart = true
                this.$store.commit("cart/append" , convertToCart(item , res , repo.cartSerial))
            })
        }
    },
})
